.editor-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
}

.editor-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f9f9f9;
  width: 80%;
  transition: margin-left 0.3s ease;
}

.sidebar {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, width 0.3s ease;
  transform: translateX(100%);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  padding: 20px;
  z-index: 10;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-toggle-btn {
  position: absolute;
  bottom: 20px;
  left: -50px;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-toggle-btn:hover {
  background-color: #0056b3;
}

.button-group-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.export-btn, .save-btn, .invite-btn, .shortcut-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 25px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #007bff;
  color: white;
  border: none;
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
}

.export-btn:hover, .save-btn:hover, .invite-btn:hover, .shortcut-toggle-btn:hover {
  transform: scale(1.05);
  background-color: #0056b3;
}

.export-btn .fa, .save-btn .fa, .invite-btn .fa, .shortcut-toggle-btn .fa {
  margin-right: 5px;
}

.invite-collaborator .form-label {
  font-weight: bold;
}

.hidden {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  transition: opacity 0.3s ease;
  display: none;
}

.overlay.open {
  display: block;
}

.status {
  font-weight: bold;
}

.status.online {
  color: green;
}

.status.offline {
  color: red;
}

.typora-content {
  margin: 40px;
  line-height: 1.6;
  font-size: 16px;
  font-family: 'Georgia', serif; /* Utilisation d'une police plus professionnelle */
  color: #333; /* Couleur de texte douce pour une meilleure lisibilité */
}

.typora-content h1, .typora-content h2, .typora-content h3, .typora-content h4, .typora-content h5, .typora-content h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: 'Arial Black', Gadget, sans-serif; /* Police d'en-tête distincte */
  color: #2c3e50; /* Couleur d'en-tête plus sombre pour une meilleure distinction */
}

.typora-content pre {
  background: #f5f5f5;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  overflow-x: auto;
  font-size: 14px; /* Taille de police plus petite pour le code */
  color: #2d2d2d; /* Couleur du texte du code */
}

.typora-content code {
  background: #f5f5f5;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px; /* Taille de police plus petite pour le code */
  color: #d6336c; /* Couleur du texte du code pour plus de contraste */
}

.typora-content blockquote {
  border-left: 4px solid #d0d0d0;
  padding: 0.5em 1em;
  color: #666;
  background: #f9f9f9;
  margin: 1em 0;
  border-radius: 3px;
  font-style: italic; /* Italique pour les citations */
  font-family: 'Georgia', serif; /* Police différente pour les citations */
}

.typora-content img {
  display: block;
  margin: 0 auto;
  max-width: 100%; /* Assurez-vous que les images ne dépassent pas le conteneur */
  border: 1px solid #ddd; /* Bordure subtile autour des images */
  padding: 5px; /* Espacement autour des images */
  border-radius: 3px; /* Coins arrondis pour les images */
}

.typora-content ol, .typora-content ul {
  margin: 0 0 1em 2em;
  padding-left: 1em; /* Ajout d'espacement à gauche */
}

.typora-content ol li, .typora-content ul li {
  margin-bottom: 0.5em; /* Espacement entre les éléments de liste */
}

.typora-content p {
  margin: 0 0 1em;
}

.typora-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
  font-size: 14px; /* Taille de police plus petite pour les tableaux */
}

.typora-content table, .typora-content th, .typora-content td {
  border: 1px solid #ddd; /* Bordure autour des tableaux */
  padding: 0.5em;
}

.typora-content th {
  background-color: #f4f4f4; /* Couleur d'arrière-plan pour les en-têtes de tableau */
  font-weight: bold;
}

.typora-content td {
  background-color: #ffffff; /* Couleur d'arrière-plan pour les cellules de tableau */
}

.typora-content a {
  color: #007bff; /* Couleur des liens */
  text-decoration: none; /* Pas de soulignement pour les liens */
}

.typora-content a:hover {
  text-decoration: underline; /* Soulignement au survol */
}

.toc {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
}

.toc h2 {
  margin-top: 0;
}

.toc ul {
  list-style: none;
  padding-left: 0;
}

.toc li {
  margin: 5px 0;
}

.toc a {
  text-decoration: none;
  color: #007bff;
}

.toc a:hover {
  text-decoration: underline;
}

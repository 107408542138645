/* src/components/Home.css */
.home-container {
    padding: 40px 0;
  }
  
  .btn-login, .btn-signup {
    max-width: 150px; /* Assurez-vous que la largeur est la même */
    min-width: 150px; /* Assurez-vous que la largeur est la même */
    font-size: 1.1rem;
    display: flex;
    align-items: center;
  }
  
  .feature-card, .beta-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon, .beta-icon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .beta-card .beta-icon {
    color: #28a745;
  }
  
/* src/styles.css */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    color: #343a40;
  }
  
  .navbar-brand, .nav-link, .btn-link {
    color: #343a40 !important;
    font-weight: bold;
  }
  
  .container {
    margin-top: 50px;
  }
  
  h2 {
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .form-control {
    margin-bottom: 15px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-primary, .btn-secondary {
    width: 100%;
    margin-top: 10px;
  }
  
  .navbar {
    border-bottom: 2px solid #007bff;
  }
  
  .navbar-toggler {
    border-color: #007bff;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: #007bff;
  }
  
  .navbar-light .navbar-nav .nav-link:hover {
    color: #0056b3;
  }
  
  .btn-link {
    color: #007bff;
  }
  
  .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
  }
  
  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  textarea.form-control {
    height: auto;
    min-height: 200px;
  }
  
  .markdown-preview {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
  }
  

  
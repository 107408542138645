/* src/components/Header.css */
.custom-navbar {
    background-color: #343a40; /* Couleur de fond sombre */
    padding: 10px 20px;
  }
  
  .logo {
    height: 50px; /* Ajustez la taille du logo */
    width: auto;
  }
  
  .brand-text {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: bold;
  }
  
  /* Styles plus spécifiques pour les liens de navigation */
  .custom-navbar .navbar-nav .nav-link {
    color: #ffffff !important; /* Couleur claire pour les liens */
    margin-right: 50px;
    font-size: 1.1rem;
    transition: color 0.3s ease-in-out;
  }
  
  .custom-navbar .navbar-nav .nav-link:hover {
    color: #61dafb !important; /* Couleur d'effet survol */
  }
  
  .nav-button {
    margin-left: 10px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .nav-button:hover {
    transform: scale(1.1);
    background-color: #61dafb !important;
    color: #343a40 !important;
  }
  
  .ml-3 {
    margin-left: 1rem !important;
  }
  
  .ml-auto {
    margin-left: auto !important;
    margin-right: 30px;
  }
